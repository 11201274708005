import * as React from "react"
import Menu from '../components/menu'
import SubPageHero from '../components/sub-page-hero'
import FooterBanner from '../components/footer-banner'
import Footer from '../components/footer'
import styled from 'styled-components'
import HelmetInfo from '../components/helmet-info'
import squiggles from '../images/squiggles.svg'
import 'animate.css'

const ResponiveVideoContainer = styled.div`
     max-width: 1000px;
     margin: 20px auto;  

    .video-wrapper { 
        padding:56.25% 0 0 0;
        position:relative;
    } 

    iframe {
        position:absolute;
        top:0;
        left:0;
        width:100%;
        height:100%;
    }

    .hero-play {
        max-width: 100px;
    }
`

const Video = () => {
  return (    
      <>

      <ResponiveVideoContainer>
          <div className='video-wrapper'>
            <iframe title="Fizzpack Reel" src="https://player.vimeo.com/video/447435118?title=0" frameBorder="0" allow="autoplay; fullscreen; picture-in-picture" allowFullScreen></iframe>
          </div>
      </ResponiveVideoContainer><script src="https://player.vimeo.com/api/player.js"></script>

      </>
  )
}

const IndexPage = () => {
  return (

    <>
    <HelmetInfo />
    <Menu/>
    <main>
      <SubPageHero>
          <h1 className="animate__animated animate__bounceInDown">Portfolio</h1>
          <img className="hero-squiggles" src={squiggles} alt="decorative squiggles" />
      </SubPageHero>
      <Video />
      <FooterBanner title="Grow Your Business: Video Ads That Convert" linkUrl="/contact-us" linkText="Contact Us"/>
    </main>
    <Footer/>
    </>
  )
}

export default IndexPage
