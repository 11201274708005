import React from 'react';
import styled from 'styled-components'
import StyledLink from './styledLink'
import bg from '../images/bg-rough-top.svg'


const FooterInfoBanner = styled.div`
    text-align: center;
    color: white;
    background-image: url(${bg}), linear-gradient(160deg, #c300ff, #e43f35 50%, #c8409e);
    background-size: cover;
    background-repeat: repeat-y;
    padding-top: 226px;
    padding-bottom: 113px;
    background-position: top;

    h2 {
        font-size: 2.2rem;
    }

    &::before {
        /* Hack to fix glitchy line appearing under background-image */
        content: "";
        display: block;
        background: #fff;
        height: 20px;
        top: -243px;
        position: relative;
    }
`

const FooterBanner = ({ title, linkUrl, linkText }) => {
    return(

    <FooterInfoBanner>
        <h2>{title}</h2>
        <StyledLink to={linkUrl} bgcolor={'#fff'} textcolor={'#f60057'}>{linkText}</StyledLink>
    </FooterInfoBanner>

    )   
}

export default FooterBanner;